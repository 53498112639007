/* from https://www.w3schools.com/js/js_cookies.asp */

export const setCookie = (cname, cvalue, exdays = 30) => {
  let d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

export const getCookie = cname => {
  let name = cname + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const checkCookie = () => {
  let user = getCookie('username');
  if (user !== '') {
    console.log('Welcome again ' + user);
  } else {
    user = prompt('Please enter your name:', '');
    if (user !== '' && user != null) {
      setCookie('username', user, 365);
    }
  }
};
